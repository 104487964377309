import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      feeds: [],
      loading: false,
      error: null,
      contributors:[],
      followedOrgs:[],
      allContributors:[],
      reactedFeed: {},
      innerHeight:null,
      feedPage: 1,
      hasMoreFeedPages: true,
      recommendedConnections: [],
      recommendedOrganizations: [],
      postActivity: [],
      commentActivity: [],
      loadingFollowers: false,
      loadingFollowedOrgs: false,
      activityLoader: false,
      feedComments: [],
    };
  },
  actions,
  mutations,
  getters,
};
