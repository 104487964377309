import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import ApiService from "./services/api.service.js";
import TokenService from "./services/storage.service.js";
import store from "./store/index.js";
import RequestInterceptor from "./services/interceptors.service.js";
import clickOutside from "./directives/click-outside";
import MultipleEvents from "./directives/multiple-events.js";
import mitt from "mitt";
import Popper from "vue3-popper";
import "@/assets/style.css";
import vue3GoogleLogin from 'vue3-google-login'
import Col from "ant-design-vue/es/col";
import Row from "ant-design-vue/es/row";
import Select from "ant-design-vue/es/select";
import Modal from "ant-design-vue/es/modal";
import Drawer from "ant-design-vue/es/drawer";
import Collapse from "ant-design-vue/es/collapse";
import Form from "ant-design-vue/es/form";
import Button from "ant-design-vue/es/button";
import Textarea from "ant-design-vue/es/input/TextArea";
import Pagination from "ant-design-vue/es/pagination";
// import InputPassword from "ant-design-vue/es/input";
import Input from "ant-design-vue/es/input";
import Dropdown from "ant-design-vue/es/dropdown";
import Tabs from "ant-design-vue/es/tabs";
import Carousel from "ant-design-vue/es/carousel";
import Skeleton from "ant-design-vue/es/skeleton";
import Menu from "ant-design-vue/es/menu";
import Radio from "ant-design-vue/es/radio";
import Checkbox from "ant-design-vue/es/checkbox";
import DatePicker from "ant-design-vue/es/date-picker";
import Upload from "ant-design-vue/es/upload";
import Tooltip from "ant-design-vue/es/tooltip";
import Spin from "ant-design-vue/es/spin";
import Tree from "ant-design-vue/es/tree";
import TreeSelect from "ant-design-vue/es/tree-select";
import TimePicker from "ant-design-vue/es/time-picker";
import AutoComplete from "ant-design-vue/es/auto-complete";
import Typography from "ant-design-vue/es/typography";
import BreadCrumb from "ant-design-vue/es/breadcrumb"


import "ant-design-vue/es/col/style/css";
import "ant-design-vue/es/row/style/css";
import "ant-design-vue/es/select/style/css";
import "ant-design-vue/es/modal/style/css";
import "ant-design-vue/es/drawer/style/css";
import "ant-design-vue/es/collapse/style/css";
import "ant-design-vue/es/form/style/css";
import "ant-design-vue/es/button/style/css";
import "ant-design-vue/es/input/style/css"; // for Textarea (since it's part of Input component)
import "ant-design-vue/es/pagination/style/css";
// import "ant-design-vue/es/input/style/css"; // for InputPassword
// import "ant-design-vue/es/input/style/css"; // for Input
import "ant-design-vue/es/dropdown/style/css";
import "ant-design-vue/es/tabs/style/css";
import "ant-design-vue/es/carousel/style/css";
import "ant-design-vue/es/skeleton/style/css";
import "ant-design-vue/es/menu/style/css";
import "ant-design-vue/es/radio/style/css";
import "ant-design-vue/es/checkbox/style/css";
import "ant-design-vue/es/date-picker/style/css";
import "ant-design-vue/es/upload/style/css";
import "ant-design-vue/es/tooltip/style/css";
import "ant-design-vue/es/spin/style/css";
import "ant-design-vue/es/tree/style/css";
import "ant-design-vue/es/tree-select/style/css";
import "ant-design-vue/es/time-picker/style/css";
import "ant-design-vue/es/auto-complete/style/css";
import "ant-design-vue/es/typography/style/css";
import "ant-design-vue/es/breadcrumb/style/css";

import indexdb from "./utils/indexdb.js";
import VuexGlobalActionPlugin from "./plugins/toast.plugin.js";
// import VueGoogleMaps from "@fawmi/vue-google-maps";


// fetching hashed files
const fontFiles = require.context('@/assets/fonts/Roboto', false, /\.(woff2)$/);
const fontPromises = [];

fontFiles.keys().forEach((font) => {
  const fontFileName = fontFiles(font).split('/').pop();
  const fontUrl = `/fonts/${fontFileName}`;
  // creating a preload link for the webfont files  
  const link = document.createElement('link');
    link.rel = 'preload';
    link.href = fontUrl;
    link.as = 'font';
    link.type = 'font/woff2';
    link.crossOrigin = 'anonymous';
    document.head.appendChild(link);

    // create promises
    const fontPromise = new FontFace('Roboto', `url(${fontUrl})`).load();
    fontPromises.push(fontPromise);
});

const app = createApp(App);

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_ROOT_API);
// initializing indexdb
indexdb.initialize()

// If token exists set header
// if (TokenService.getToken()) {
//     ApiService.setAuthorizationHeader();
// }

const components = [
  Col,
  Row,
  Select,
  Modal,
  Drawer,
  Collapse,
  Form,
  Button,
  Textarea,
  Pagination,
  Input.InputPassword,
  Input,
  Dropdown,
  Tabs,
  Carousel,
  Skeleton,
  Menu,
  Radio,
  Checkbox,
  DatePicker,
  Upload,
  Tooltip,
  Spin,
  Tree,
  TreeSelect,
  TimePicker,
  AutoComplete,
  Typography,
  BreadCrumb
];

RequestInterceptor.interceptors();

const emitter = mitt();
// app.use(Antd);
components.forEach((component) => {
  app.use(component);
});
// app.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY",
//   },
// });
app.use(router);
app.use(store);
app.use(Popper);
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})
app.directive("click-outside", clickOutside);
app.directive("MultipleEvents", MultipleEvents);
app.use(VuexGlobalActionPlugin, store)
app.config.globalProperties.emitter = emitter;

Promise.all(fontPromises).then(() => {
  // load application when all the fonts have been loaded 
  app.mount('#app');
});
