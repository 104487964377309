export default {
  SET_FEEDS(state, feeds) {
    if (state.feedPage === 1)
      state.feeds = [...feeds]
    else 
      state.feeds = [...state.feeds, ...feeds]
  },
  setFollowed(state, payload) {
    state.contributors = payload;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_FOLLOWED_ORGS(state, payload){
    state.followedOrgs = payload
  },
  SET_ALL_CONTRIBUTORS(state, payload){
    state.allContributors = payload
  },
  // feedReaction(state, payload) {
  //   state.feeds = [...state.feeds.map(feed => {
  //     if (feed.id === payload.id) {
  //       feed = { ...payload }
  //     }
  //     return feed
  //   })]
  // },
  feedReaction(state, payload) {
    const index = state.feeds.findIndex(feed => feed.id === payload.id);
    if (index !== -1) {
      state.feeds[index] = { ...payload };
    }
  },
  SET_SCROLL_HEIGHT(state, payload){
    state.innerHeight = payload
  },
  SET_FEED_PAGE_SIZE(state, payload) {
    state.feedPage = payload
  },
  UPDATE_FEED_LIST (state,payload) {
    payload.forEach((feed)=>{
      const feedExists = state.feeds.find(existingFeed => existingFeed.id === feed.id)
      if(!feedExists)
        state.feeds.push(feed)
    })

    state.feeds = [...state.feeds]
  },
  SET_HAS_MORE_PAGES(state, hasMorePages) {
    state.hasMoreFeedPages = hasMorePages 
  },
  SET_RECOMMENDED_CONNECTIONS(state, recommendations){
    state.recommendedConnections = recommendations
  },
  SET_RECOMMENDED_ORGANIZATIONS(state, recommendations){
    state.recommendedOrganizations = recommendations
  },
  SET_ACTIVITY_POST(state, payload=[]){
    state.postActivity = payload
  },
  SET_ACTIVITY_COMMENT(state, payload=[]){
    state.commentActivity = payload
  },
  SET_FEED_COMMENTS(state, feedComments){
    state.feedComments = feedComments
    // feedComments.map(comments=>{
    //   const foundComment = state.feedComments.find(comment=> comment)
    // })
    // state.feedComments = [...state.feedComments, ...feedComments]
  }
};
