export default {
  // SET_FOLDER_TABS(state, payload) {
  //   state.folderTabsList = payload;
  // },
  // SET_FOLDER_TABS_SKELETON(state, payload) {
  //   state.folderTabsSkeleton = payload;
  // },
  SET_FOLDER_TREE(state, payload) {
    state.foldersTree = payload;
  },
  SET_FOLDER_TREE_SKELETON(state, payload) {
    state.folderTreeSkeleton = payload;
  },
  SET_PARENT_FOLDER_DETAILS(state, payload) {
    state.parentFolderDetails = payload;
  },
  SET_CHILD_FOLDER_DETAILS(state, payload) {
    state.childFolderDetails = payload;
  },
  SET_NESTED_CHILD_FOLDER_DETAILS(state, payload) {
    state.nestedChildFolderDetails = payload;
  },
  SET_TAB_NAME(state, payload) {
    state.tabName = payload;
  },
  SET_FOLDER_ARTICLE_CONTENT(state, payload) {
    state.folderArticleContent = payload.results;
  },
  SET_FOLDER_SUGGESTED_ARTICLE_CONTENT(state, payload) {
    state.folderSuggestedArticleContent = payload.results;
  },
  SET_FOLDER_MEDIA_CONTENT(state, payload) {
    state.folderMediaContent = payload.results;
  },
  SET_FOLDER_PODCASTS_CONTENT(state, payload) {
    state.folderPodcastContent = payload.results;
  },
  SET_FOLDER_BOOKS_CONTENT(state, payload) {
    state.folderBookContent = payload.results;
  },
  SET_FOLDER_ARTICLE_SKELETON(state, payload) {
    state.folderArticleSkeleton = payload;
  },
  SET_FOLDER_MEDIA_SKELETON(state, payload) {
    state.folderMediaSkeleton = payload;
  },
  SET_FOLDER_PODCASTS_SKELETON(state, payload) {
    state.folderPodcastSkeleton = payload;
  },
  SET_ARCHIVED_FOLDER(state, payload) {
    state.archivedFolder = payload;
  },

  // This is institute users users
  SET_INSTITUTE_USERS(state, payload) {
    const admins = payload.admins;
    const faculty = payload.faculty;
    const students = payload.students;

    let allUsers = admins.concat(faculty).concat(students);

    const userList = allUsers.map((user) => {
      return {
        label: user.full_name,
        value: user.id,
        key: user.email,
        icon: user.profile_picture,
      };
    });

    state.instituteUsers = payload.students;
  },

  // This is folders permissions users
  SET_USERS(state, userList) {
    // const admins = payload.admin_users;
    // const editors = payload.editors ? payload.editors : [];
    // const readers = payload.read_only_users ? payload.read_only_users : [];
    // const students = payload.students ? payload.students : [];
    // const owner = payload.owner;
    // let allUsers = admins
    //   .concat(editors)
    //   .concat(readers)
    //   .concat(students);
    // let userList = allUsers.map((user) => {
    //   let isAdmin = admins.some((admin) => admin.id === user.id);
    //   let isEditor = editors.some((editor) => editor.id === user.id);
    //   let isStudent = students.some((student) => student.id === user.id);
    //   return {
    //     name: user.full_name,
    //     id: user.id,
    //     profilePicture: user.profile_picture,
    //     email: user.email,
    //     isOwner: owner.id === user.id,
    //     isAdmin: isAdmin,
    //     isEditor: isEditor,
    //     isStudent: isStudent,
    //     role: isAdmin ? "Admin" : isStudent ? "Viewer" : "Editor",
    //   };
    // });
    // state.usersWithFolderAccessCounts = payload;
    state.usersWithFolderAccess = userList;
  },
  SET_FOLDER_COMMENTS(state, payload) {
    state.folderComments = payload;
  },
  SET_FOLDER_ACTIVITY(state, payload) {
    state.folderActivity = [...state.folderActivity, ...payload.results];
  },
  SET_FOLDER_ACTIVITY_SKELETON(state, payload) {
    state.folderActivitySkeleton = payload;
  },
  SET_FOLDER_SUGGESTED_ARTICLE_SKELETON(state, payload) {
    state.folderSuggestedArticleSkeleton = payload;
  },
  SET_REQUESTED_FOLDER: (state, id) => {
    state.requestedFolderId = id;
    if (id) state.folderRequested = true;
    else state.folderRequested = false;
  },
  ADD_USERS(state, paylaod) {
    state.usersWithFolderAccess = paylaod;
  },
  SET_ARCHIVED_TAB(state, paylaod) {
    state.archive = paylaod
  },
  SET_FOLDER_TABS(state, folderTabs){
    state.folderTabs = folderTabs
  },
  TOGGLE_ARCHIVE(state) {
    state.showArchive = !state.showArchive;
  },
  SET_ARCHIVE(state, value) {
    state.showArchive = value;
  },
  SET_ACTIVE_FOLDER_TAB(state, activeFolderTab){
    state.activeFolderTab = activeFolderTab
  }
};
