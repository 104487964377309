export default {
  SET_ARTICLES(state, payload) {
    if (payload.results) {
      state.articles = payload.results;
      state.nextUrl = payload.next;
      state.previousUrl = payload.previous;
      state.totalArticles = payload.results.length;
      state.totalResults = payload.total_results;
      state.currentPage = payload.page_number;
      state.fetchedSources = payload.fetched_from;
    } else {
      state.articles = payload;
    }
  },
  SEARCHING_PDB_ARTICLES(state, payload) {
    state.searchingPDBArticles = payload;
  },
  SEARCHING_INSIGHTS_ARTICLES(state, payload) {
    state.searchingInsightsArticles = payload;
  },
  SET_LIMIT_ERROR(state, payload) {
    state.limitError = payload;
  },
  SET_ALL_SOURCES(state, payload) {
    state.sources = payload;
  },
  SEARCHING_PDB_SOURCES(state, payload) {
    state.searchingPdbSources = payload;
  },
  SET_ALL_INSIGHTS_SOURCES(state, payload) {
    state.insightsSources = payload;
  },
  SEARCHING_INSIGHTS_SOURCES(state, payload) {
    state.searchingInsightsSources = payload;
  },
  SET_QUESTION_RATING(state, payload) {
    state.sources = payload;
  },
  SET_INSIGHTS(state, payload) {
    if (payload.results) {
      state.insights = payload.results;
      state.fetchedInsightsSources = payload.fetched_from;
      state.insightsNextUrl = payload.next;
      state.insightsPreviousUrl = payload.previous;
      state.insightsTotalArticles = payload.results.length;
      state.insightsTotalResults = payload.total_results;
      state.insightsCurrentPage = payload.page_number;
    } else {
      state.insights = payload;
    }
  },
  SET_SAVE_SEARCH_FILTER(state, payload) {
    state.savedSearchFilter = payload;
  },
  SET_ARTICLE_SEARCH_HISTORY(state, payload) {
    state.articleSearchHistory = payload;
  },
  SET_DISCOVER_ARTICLES_COMMENTS(state, payload) {
    state.discoverArticleComments = payload;
  },
  SET_DISCUSSION_COMMENTS(state, payload) {
    state.topicComments = payload;
  },
  SET_PRIVATE_DISCUSSION_COMMENTS(state, payload) {
    state.privateTopicComments = payload;
  },
  SET_PUBLIC_DISCUSSION_COMMENTS(state, payload) {
    state.publicTopicComments = payload;
  },
  SET_DISCUSSION_LOADER(state, payload) {
    state.discussionSkeletonLoader = payload;
  },
  SET_SEARCH_QUERY(state, payload) {
    state.searchQuery = payload;
  },
  SET_SEARCHED_TOPIC(state, payload) {
    state.seachedTopic = payload;
  },
  SET_START_DATE(state, payload) {
    state.startDate = payload;
  },
  setSelectedTopic(state, data) {
    state.topic = data;
  },
  SET_END_DATE(state, payload) {
    state.endDate = payload;
  },
  SET_INSIGHTS_START_DATE(state, payload) {
    state.insightsStartDate = payload;
  },
  SET_INSIGHTS_END_DATE(state, payload) {
    state.insightsEndDate = payload;
  },
  SET_INCLUDED_SOURCES(state, payload) {
    if (payload) {
      state.selectedIncludeSources = payload.map(source=>{
        return Object.hasOwn(source, 'key') ? 
        {
          source_name: source?.key, 
          logo_url: source?.logo_url ?? '', 
          link: source?.link ?? '' 
        }
        : 
        {
          ...source
        }  
      })
      if (state.selectedIncludeSources) {
        state.includedSources = state.selectedIncludeSources
          .map((source) => source.source_name)
          .join(",");
      }
    } else {
      state.includedSources = "";
      state.selectedIncludeSources = [];
    }
  },
  SET_EXCLUDED_SOURCES(state, payload) {
    if (payload) {
      state.selectedExcludeSources =  payload.map(source=>{
        return Object.hasOwn(source, 'key') ? 
        {
          source_name: source?.key, 
          logo_url: source?.logo_url ?? '', 
          link: source?.link ?? '' 
        }
        : 
        {
          ...source
        }  
      });
      if (state.selectedExcludeSources) {
        state.excludedSources = state.selectedExcludeSources
          .map((source) => source.source_name)
          .join(",");
      }
    } else {
      state.excludedSources = "";
      state.selectedExcludeSources = [];
    }
  },
  SET_INSIGHTS_INCLUDED_SOURCES(state, payload) {
    if (payload) {
      state.selectedInsightsIncludeSources =  payload.map(source=>{
        return Object.hasOwn(source, 'key') ? 
        {
          source_name: source?.key, 
          logo_url: source?.logo_url ?? '', 
          link: source?.link ?? '' 
        }
        : 
        {
          ...source
        }  
      })
      if (state.selectedInsightsIncludeSources) {
        state.insightsIncludedSources = state.selectedInsightsIncludeSources
          .map((source) => source.source_name)
          .join(",");
      }
    } else {
      state.insightsIncludedSources = "";
      state.selectedInsightsIncludeSources = [];
    }
  },
  SET_INSIGHTS_EXCLUDED_SOURCES(state, payload) {
    if (payload) {
      state.selectedInsightsExcludeSources = payload.map(source=>{
        return Object.hasOwn(source, 'key') ? 
        {
          source_name: source?.key, 
          logo_url: source?.logo_url ?? '', 
          link: source?.link ?? '' 
        }
        : 
        {
          ...source
        }  
      })
      if (state.selectedInsightsExcludeSources) {
        state.insightsExcludedSources = state.selectedInsightsExcludeSources
          .map((source) => source.source_name)
          .join(",");
      }
    } else {
      state.insightsExcludedSources = "";
      state.selectedInsightsExcludeSources = [];
    }
  },
  SET_TOPIC_SOURCE(state, payload) {
    state.topicSource = payload;
  },
  SET_TOPIC_URL(state, payload) {
    state.invitedTopicURL = payload;
  },
  SET_SPECIFIC_NEWS_SOURCES(state, payload) {
    if (Array.isArray(payload)) {
      state.selectedSpecificSources = [...payload];
      // state.selectedSpecificSources.push(payload);
    } else if (!Array.isArray(payload)) {
      state.selectedSpecificSources.push(payload);
    } else state.selectedSpecificSources = [];
  },
  SET_INVITED_ARTICLE_ID(state, invitedArticleId) {
    state.invitedArticleId = invitedArticleId;
  },
  SET_UPDATE_SOURCES(state, payload) {
    state.updateSources = payload;
  },
  SET_SORT_FILTER(state, payload) {
    state.sortFilter = payload;
  },
  SET_ARTICLE_READ_VIEW(state, payload) {
    state.articleReadView = payload;
  },
  SET_TOPIC_DETAILS(state, payload) {
    state.topicDetails = payload;
  },
  SET_FILTERS_COUNT(state, payload) {
    state.filterCounts = payload;
  },
  SET_TOPIC_ROUTE(state, payload) {
    state.topicRoute = payload;
  },
  SET_OLD_SELECTED_TOPIC(state, payload) {
    state.oldTopics = payload;
  },
  SET_DISCUSSION_COLLAPSE(state, payload) {
    state.discussionCollapse = payload;
  },
  SET_MODULES(state, payload) {
    state.discoverModules = payload;
  },
  SET_INSIGHTS_PAGE_OFFSET(state, payload) {
    state.insightsPageOffset = payload;
  },
  SET_ARTICLES_PAGE_OFFSET(state, payload) {
    state.articlePageOffset = payload;
  },
  PDB_INCLUDED_KEYWORDS(state, payload) {
    state.PDBIncludeKeywords = payload;
  },
  PDB_EXCLUDED_KEYWORDS(state, payload) {
    state.PDBExcludeKeywords = payload;
  },
  INSIGHTS_INCLUDED_KEYWORDS(state, payload) {
    state.insightsIncludeKeywords = payload;
  },
  INSIGHTS_EXCLUDED_KEYWORDS(state, payload) {
    state.insightsExcludeKeywords = payload;
  },
  SET_SUGGESTIONS(state, suggestions) {
    state.suggestions = suggestions.map((topic) => ({
      label: topic.interest, // Display text (name of the topic)
      value: topic.interest, // Actual value for selection (ID of the topic)
    }));
  },
  SET_SELECTED_TOPIC(state, topic) {
    state.selectedTopic = topic;
  },
  PUSH_NEW_COMMENT(state, comment){
    if(comment.parent){
      state.discoverArticleComments.forEach(parentComment => {
        if(parentComment.id === comment.parent){
          parentComment.child.push(comment)
        }
      })
    }
    else
      state.discoverArticleComments.unshift(comment)
  },
  SET_SELECTED_DISCOVER_MODULE(state, module){
    state.selectedModule = module
  },
  SET_TRENDING_NEWS_FILTERS(state, filters){
    state.trendingNewsFilter = filters
  },
  SET_TRENDING_RESEARCH_FILTERS(state, filters){
    state.trendingResearchFilter = filters
  },
  SET_ACTIVE_TOPIC(state, topic){
    state.activeTopicTab = topic
  },
  SET_PDB_EXACT_KEYWORD(state, pdbExactKeyword){
    state.pdbExactKeyword = pdbExactKeyword
  },
  SET_INSIGHTS_EXACT_KEYWORD(state, insightExactKeyword){
    state.insightExactKeyword = insightExactKeyword
  }
};
