export default {
  // getFolderTabList(state) {
  //   return state.folderTabsList;
  // },
  // getFolderTabsLoader(state) {
  //   return state.folderTabsSkeleton;
  // },
  getFolderList(state) {
    return state.foldersTree;
  },
  getFolderLoader(state) {
    return state.folderTreeSkeleton;
  },
  getParentFolderDetails(state) {
    return state.parentFolderDetails;
  },
  getChildFolderDetails(state) {
    return state.childFolderDetails;
  },
  getNestedChildFolderDetails(state) {
    return state.nestedChildFolderDetails;
  },
  getTabName(state) {
    return state.tabName;
  },
  getFolderArticleContent(state) {
    return state.folderArticleContent ?? [];
  },
  getFolderSuggestedArticleContent(state) {
    return state.folderSuggestedArticleContent ?? [];
  },
  getFolderMediaContent(state) {
    return state.folderMediaContent ?? [];
  },
  getFolderPodcastContent(state) {
    return state.folderPodcastContent ?? [];
  },
  getFolderBooksContent(state) {
    return state.folderBookContent ?? [];
  },
  getFolderArticlesContentLoader(state) {
    return state.folderArticleSkeleton;
  },
  getFolderMediaContentLoader(state) {
    return state.folderMediaSkeleton;
  },
  getFolderPodcastContentLoader(state) {
    return state.folderPodcastSkeleton;
  },
  getArchivedFolder(state) {
    return state.archivedFolder;
  },
  getInstituteUsers(state) {
    return state.instituteUsers;
  },
  getUsersWithFolderAccess(state) {
    return state.usersWithFolderAccess;
  },
  getUsersWithFolderAccessCounts(state) {
    return state.usersWithFolderAccessCounts;
  },
  getFolderComments(state) {
    return state.folderComments;
  },
  getFolderActivity(state) {
    return state.folderActivity;
  },
  getFolderActivityLoader(state) {
    return state.folderActivitySkeleton;
  },
  isFolderRequested: (state) => state.folderRequested,
  requestedFolderId: (state) => state.requestedFolderId,
  getArchive(state) {
    return state.archive
  },
  getFolderTabs: (state)=> state.folderTabs,
  isArchiveVisible: (state) => state.showArchive,
  activeFolderTab: (state) => state.activeFolderTab,
  folderSuggestedArticleSkeleton: (state) => state.folderSuggestedArticleSkeleton
};
