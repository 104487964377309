import Cookies from 'js-cookie'
export default {
  getCertificates(state) {
    return state.certificates;
  },
  getExperienceSkeletonLoader(state) {
    return state.experienceSkeletonLoader;
  },
  getExperiences(state) {
    return state.experiences;
  },
  getTopics(state) {
    return state.topics;
  },
  getUserInterests(state) {
    return state.interests;
  },
  getUserAccountType(state) {
    return state.accountType;
  },
  getPersonalDetails(state) {
    return state.personalInfo;
  },
  getAudienceGroups(state) {
    return state.audienceGroups;
  },
  getFriends(state) {
    return state.friends;
  },
  getConnectionsLoader(state) {
    return state.isConnectionsLoading;
  },
  getTotalFriendsCount(state) {
    return state.friendsCount;
  },
  getGroupsLoader(state) {
    return state.isGroupLoadings;
  },
  getConnectionsInGroup(state) {
    return state.connectionsInGroup;
  },
  getLanguages(state) {
    return state.languages;
  },
  getLanguagesSkeletonLoader(state) {
    return state.languagesSkeletonLoader;
  },
  getTimeline(state) {
    return state.timeline;
  },
  getProfileSkeletonLoader(state) {
    return state.profileSkeletonLoader;
  },
  getProfileInfo(state) {
    return state.userProfile.profile;
  },
  isLoggedinUser(state) {
    let userId = Cookies.get("user_id");
    if (userId == state.userProfile.id) {
      return true;
    }
    return false;
  },
  connectionRequestStatus(state) {
    return state.connectionStatus;
  },
  getUserRequestCount(state) {
    return state.requestCount;
  },
  getNotificationCount(state) {
    return state.notificationCount;
  },
  getuserRequests(state) {
    return state.userRequests;
  },
  currentLoginUser() {
    return Cookies.get("user_id");
  },
  userInformation(state) {
    return state.loggedInUserDetails;
  },
  topicsCategories(state) {
    return state.userCategories;
  },
  isPaid(state) {
    if (
      state.loggedInUserDetails &&
      state.loggedInUserDetails.active_subscription !== null
    ) {
      return true;
    } else {
      return false;
    }
  },
  getNotifications(state) {
    return state.totalNotifications;
  },
  getVisibilitySubscriptionModal(state) {
    return state.visibleSubscriptionModal;
  },
  getPhotoResume(state) {
    return state.photoResume;
  },
  getPhotoResumeSkeletonLoader(state) {
    return state.photoResumeSkeletonLoader;
  },
  getSavedFoldersComments(state) {
    return state.savedFoldersComments;
  },
  getConnections(state) {
    return state.connections;
  },
  institute(state) {
    return state.loggedInUserDetails.profile.institute;
  },
  getSelectedUserProfile: (state) => state.userProfile,
  getEvents: (state) => state.events,
  getEventsSkeletonLoader(state) {
    return state.eventsSkeletonLoader;
  },
  getEventsNext(state){
    return state.eventsNext;
},
getEventsPrevious(state){
  return state.eventsPrevious;
},
getTotalEvents(state){
  return state.totalEvents;
},
getEventsCount(state){
  return state.eventsCount;
},
selectedEvent: (state) => state.selectedEvent,
currentActiveTab: (state) => state.currentActiveTab,
selectedUser: (state) => state.selectedUser,
searchedLocationResults: (state) => state.searchedLocationResults,
publications: (state) => state.publications,
closeAccessModal: (state) => state.closeAccessModal,
isSharedProfileView: (state) => state.isSharedProfileView,
organizationLocations: (state) => state.organizationLocations.filter(org=>!org?.is_main_branch),
};