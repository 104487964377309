import ApiService from "../../../services/api.service";
import { __generateFolderFetchContentRequestUrl } from "@/store/helpers/folder.helper";

export default {
  // Getting Folder tabs
  // fetchFolderTabs(context, _) {
  //   context.commit("SET_FOLDER_TABS_SKELETON", true);
  //   return new Promise((resolve, reject) => {
  //     ApiService.get(`discover/folder-tab/list/`)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           context.commit("SET_FOLDER_TABS", response.data);
  //           context.commit("SET_FOLDER_TABS_SKELETON", false);
  //           resolve(response);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },

  // Craete new Folder tabs

  // createFolderTab(_, payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`discover/folder-tab/create/`, payload)
  //       .then((response) => {
  //         if (response.status === 201) {
  //           resolve(response);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },

  // // Edit Folder tabs

  // editFolderTab(_, payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.patch(`discover/folder-tab/update/${payload.id}/`, payload)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           resolve(response);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },

  // // Delete Folder tab

  // deleteFolderTab(_, payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.delete(`discover/folder-tab/delete/${payload}/`)
  //       .then((response) => {
  //         if (response.status === 204) {
  //           resolve(response);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },

  // Get Personal Folder Tree List in Tabs

  getPersonalFolderTree(context, payload) {
    ApiService.setAuthorizationHeader();
    context.commit("SET_FOLDER_TREE_SKELETON", true);
    return new Promise((resolve, reject) => {
      ApiService.get(`discover/folder-tab/personal/folder/tree/?name=${context.state.activeFolderTab}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_FOLDER_TREE", response.data);
            context.commit("SET_FOLDER_TREE_SKELETON", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Create a new Personal Folder

  createPersonalFolder(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`discover/folder-tab/personal/folder/create/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createFolder(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`discover/folder-tab/personal/folder/create/`, payload.folderDetails)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Edit Personal Folder

  editPersonalFolder(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/folder-tab/personal/folder/update/${payload.folder_id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  editFolder(_, {payload, folderId}) {
    const folderType = payload.folderType
    delete payload.folderType
    return new Promise((resolve, reject) => {
      ApiService.put(
        `discover/folder-tab/personal/folder/update/${folderId}/`,
        payload
      )
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Delete Personal Folders

  deletePersonalFolder(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/folder-tab/personal/folder/delete/${payload.folder_id}/`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get Class Folder Tree List in Tabs

  getFolderTree(context, folderTab=null) {
    ApiService.setAuthorizationHeader();
    context.commit("SET_FOLDER_TREE_SKELETON", true);
    const folderType = folderTab ?? context.state.activeFolderTab
    // if workspace then only the logged in user public details will be shown
    const owner_id = window.location.href.includes('workspace') ?  context.rootGetters['profile/userInformation']?.id : context.rootGetters['profile/selectedUser']?.id
    return new Promise((resolve, reject) => {
      let url = `discover/folder-tab/personal/folder/tree/?name=${folderType}`
      if (folderType === 'public_folders'){
        url += `&owner_id=${owner_id}`
      }
      ApiService.get(url)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_FOLDER_TREE", response.data);
            context.commit("SET_FOLDER_TREE_SKELETON", false);
            resolve(response);
          }
        })
        .catch((err) => {
          context.commit("SET_FOLDER_TREE_SKELETON", false);
          context.commit("SET_FOLDER_TREE", []);
          reject(err);
        });
    });
  },

  // Create a new Personal Folder

  createClassFolder(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`discover/folder-tab/institute/folder/create/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Edit Class Folder

  editClassFolder(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/folder-tab/institute/folder/update/${payload.folder_id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Delete Class Folders

  deleteClassFolder(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/folder-tab/institute/folder/delete/${payload.folder_id}`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteFolder(_, payload) {
    const folderType = payload.folderType
    delete payload.folderType
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/folder-tab/${folderType}/folder/delete/${payload.folder_id}`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get/Search Folder Article List Content

  fetchFolderArticles(context, payload) {
    context.commit("SET_FOLDER_ARTICLE_SKELETON", true);
    return new Promise((resolve, reject) => {
      const url = __generateFolderFetchContentRequestUrl(payload)
      // let requestUrl = `discover/folder-tab/folder/${payload.folderId}/search-content/?content=article`;
      // if (payload.searchQuery) {
      //   requestUrl += `&search=${payload.searchQuery}`;
      // }
      // if (payload.page) {
      //   requestUrl += `&page=${payload.page}`;
      // }
      // if (payload.pageSize) {
      //   requestUrl += `&page_size=${payload.pageSize}`;
      // }
      ApiService.get(url)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_FOLDER_ARTICLE_CONTENT", response.data);
            context.commit("SET_FOLDER_ARTICLE_SKELETON", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get/Search Folder Media List Content

  fetchFolderMedia(context, payload) {
    context.commit("SET_FOLDER_MEDIA_SKELETON", true);
    return new Promise((resolve, reject) => {
      const url = __generateFolderFetchContentRequestUrl(payload)
      ApiService.get(url)
        .then((response) => {
            context.commit("SET_FOLDER_MEDIA_CONTENT", response.data);
            context.commit("SET_FOLDER_MEDIA_SKELETON", false);
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get/Search Folder Poddcast List Content

  fetchFolderFiles(context, payload) {
    context.commit("SET_FOLDER_MEDIA_SKELETON", true);
    return new Promise((resolve, reject) => {
      const url = __generateFolderFetchContentRequestUrl(payload)
      ApiService.get(url)
        .then((response) => {
            context.commit("SET_FOLDER_PODCASTS_CONTENT", response.data);
            context.commit("SET_FOLDER_MEDIA_SKELETON", false);
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  fetchFolderBooks(context, payload) {
    context.commit("SET_FOLDER_MEDIA_SKELETON", true);
    return new Promise((resolve, reject) => {
      const url = __generateFolderFetchContentRequestUrl(payload)
      ApiService.get(url)
        .then((response) => {
            context.commit("SET_FOLDER_BOOKS_CONTENT", response.data);
            context.commit("SET_FOLDER_MEDIA_SKELETON", false);
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Save content in a new Folder

  saveContentInFolder(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/folder-tab/folder/folder-content/${payload.folder_id}/create/`,
        payload.article ? payload.article : payload.content
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Delete folder content

  deleteFolderContent(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/folder-tab/folder/folder-content/${payload.folderId}/delete/${payload.contentId}/`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get Archived Folder List

  getArchivedFolder(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/folder-tab/folder/archived/list/?is_institute=${payload}`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_ARCHIVED_FOLDER", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Archive or Unarchive a Folder

  archiveOrUnchiveFolder(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/folder-tab/folder/archive/${payload.id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Accept or Reject folder Invitation through notification

  async acceptOrRejectGlocalFoldersInvitation(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(
        `institute/institution_invitation/${payload.notification_id}/action/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get institute users

  getInstituteUsers(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`institute/users/${payload}/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_INSTITUTE_USERS", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get Folder Users Permission

  getUsersFolderPermissions(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/folder-tab/personal/folder/users/${payload.folderId}/?status=accepted`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_USERS", response.data.results);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Add new users in folders

  async addNewUsersInFolder(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(
        `discover/folder-tab/institute/folder/${payload.folderId}/add/users/`, payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Comments Of Folders Section

  fetchFolderComments(context, payload) {
    return new Promise((resolve, reject) => {
      let requestUrl = `discover/folder-tab/folder-discussion/${payload.folderId}/list/`
      if( payload.filterOptions && payload.filterOptions.user ){
        requestUrl = requestUrl + `?multiple_users=${payload.filterOptions.user}`
        if(payload.filterOptions.startDate){
          requestUrl = requestUrl + `&data_range_after=${payload.filterOptions.endDate}&data_range_before=${payload.filterOptions.startDate}`
        }
        // if(payload.filterOptions.startDate){
        //   requestUrl = requestUrl + `&data_range_after=${payload.filterOptions.endDate}`
        // }
      }else if(payload.filterOptions && payload.filterOptions.startDate){
        requestUrl = requestUrl + `?data_range_after=${payload.filterOptions.endDate}&data_range_before=${payload.filterOptions.startDate}`
       }
      ApiService.get(
        requestUrl
      )
      
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_FOLDER_COMMENTS", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createFolderComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/folder-tab/folder-discussion/${payload.folderId}/create/`,
        payload.comment
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateFolderCommentst(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `discover/folder-tab/folder-discussion/${payload.folderId}/update/${payload.commentId}/`,
        payload.comment
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteFolderComments(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/folder-tab/folder-discussion/${payload.folderId}/delete/${payload.commentId}/`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  likeFolderComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/folder-tab/${payload.commentId}/folder-discussion-action/create/`,
        payload.action
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteLikeDislike(_, commentId) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/folder-tab/${commentId}/folder-discussion-action/delete/`,
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFolderActivity(context, paylaod) {
    ApiService.setAuthorizationHeader();
    context.state.page += 1;
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/folder-tab/folder-activity/${paylaod}/list/?page_size=5&page=${context.state.page}`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_FOLDER_ACTIVITY_SKELETON", false);
            context.commit("SET_FOLDER_ACTIVITY", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
          context.state.page -= 1;
        });
    });
  },
  
  setRequestedFolderId: ({ commit }, folderId) => commit("SET_REQUESTED_FOLDER", folderId),

  async removeUserFromFolder(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(
        `discover/folder-tab/institute/folder/${payload.folderId}/remove-user/${payload.userId}/role/${payload.role}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  archiveTabActive({commit}, paylaod) {
    commit('SET_ARCHIVED_TAB', paylaod)
  },
  createFolderActivity(context, payload) {
    ApiService.setAuthorizationHeader();
    const { institute_id, instance_id, actionDetails} = payload;
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/folder-tab/folder-activity/${institute_id}/create/${instance_id}/`,
        actionDetails
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_FOLDER_ACTIVITY_SKELETON", false);
            context.commit("SET_FOLDER_ACTIVITY", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchFolderTab({commit}) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/folder-tab/list/`
      )
        .then((response) => {
          if (response.status === 200) {
            // commit('SET_FOLDER_TABS', response.data)
          }
        })
        .catch(err=>{
          // commit('SET_FOLDER_TABS',[])
        })
    })
  },

  fetchFolderSuggestedArticles({commit}, query){
    commit("SET_FOLDER_SUGGESTED_ARTICLE_SKELETON", true);
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(
        `discover/search/?query=${query}`
      )
        .then((response) => {
          if (response.status === 200) {
            commit("SET_FOLDER_SUGGESTED_ARTICLE_CONTENT", response.data);
            commit("SET_FOLDER_SUGGESTED_ARTICLE_SKELETON", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  toggleArchive({ commit }) {
    commit('TOGGLE_ARCHIVE');
  },
  setArchive({ commit }, value) {
    commit('SET_ARCHIVE', value);
  },
  setActiveFolderTab({commit}, activeFolderTab){
    commit('SET_ACTIVE_FOLDER_TAB', activeFolderTab)
  },
  emptyFolderTreeAndContent({commit}){
    commit("SET_FOLDER_TREE", []);
    commit("SET_FOLDER_SUGGESTED_ARTICLE_CONTENT", []);
    commit("SET_FOLDER_PODCASTS_CONTENT", []);
    commit("SET_FOLDER_ARTICLE_CONTENT", []);
    commit("SET_FOLDER_MEDIA_CONTENT", []);
    commit("SET_PARENT_FOLDER_DETAILS", {});
    commit("SET_CHILD_FOLDER_DETAILS", {});
    commit("SET_NESTED_CHILD_FOLDER_DETAILS", {});
    commit("SET_FOLDER_COMMENTS", []);
  },

  duplicateFolderContent(_, payload){
    const {folderId, folder_tab} = payload
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/folder-tab/personal/folder/${folderId}/dublicate/`, {folder_tab}
      )
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
  inviteToFolders(_, {folderId, invitationList}){
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/folder-tab/personal/folder/${folderId}/invitation/`, invitationList
      )
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


};
